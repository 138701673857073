import React from 'react';

const CTARatingsIcon = () => (
  <svg width="58" height="43" viewBox="0 0 58 43" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 37.8997C15 35.1937 17.1937 33 19.8997 33H37.8109C40.5169 33 42.7106 35.1937 42.7106 37.8997C42.7106 40.6058 40.5169 42.7994 37.8109 42.7994H19.8997C17.1937 42.7994 15 40.6058 15 37.8997Z"
      fill="#171717"
    />
    <path
      d="M21.6943 35.5282H19.7213V40.1274H20.8189V38.5595H21.7988C22.8833 38.5595 23.4843 37.8278 23.4843 37.0178C23.4843 36.2077 22.9355 35.5282 21.6943 35.5282ZM21.6355 37.6711H20.8189V36.469H21.6355C22.0862 36.469 22.3868 36.6584 22.3868 37.07C22.3868 37.4359 22.0862 37.6711 21.6355 37.6711ZM26.1003 35.4237C24.6108 35.4237 23.7485 36.5017 23.7485 37.8278C23.7485 39.154 24.6108 40.232 26.1003 40.232C27.5898 40.232 28.4522 39.154 28.4522 37.8278C28.4522 36.5017 27.5898 35.4237 26.1003 35.4237ZM26.1003 39.2912C25.349 39.2912 24.846 38.6379 24.846 37.8278C24.846 37.0178 25.349 36.3645 26.1003 36.3645C26.8516 36.3645 27.3547 37.0178 27.3547 37.8278C27.3547 38.6379 26.8516 39.2912 26.1003 39.2912ZM31.1149 35.4237C29.6254 35.4237 28.763 36.5017 28.763 37.8278C28.763 39.154 29.6254 40.232 31.1149 40.232C32.6044 40.232 33.4667 39.154 33.4667 37.8278C33.4667 36.5017 32.6044 35.4237 31.1149 35.4237ZM31.1149 39.2912C30.3636 39.2912 29.8606 38.6379 29.8606 37.8278C29.8606 37.0178 30.3636 36.3645 31.1149 36.3645C31.8662 36.3645 32.3692 37.0178 32.3692 37.8278C32.3692 38.6379 31.8662 39.2912 31.1149 39.2912ZM37.9076 40.1274L36.7774 38.4093C37.385 38.148 37.6986 37.6057 37.6986 37.0178C37.6986 36.2077 37.1498 35.5282 35.9085 35.5282H33.9356V40.1274H35.0331V38.5595H35.6864L36.6533 40.1274H37.9076ZM35.0331 36.469H35.8497C36.3005 36.469 36.601 36.6584 36.601 37.07C36.601 37.4359 36.3005 37.6711 35.8497 37.6711H35.0331V36.469Z"
      fill="#DFDFDF"
    />
    <path
      d="M7 25C7 20.0294 11.0294 16 16 16H41C45.9706 16 50 20.0294 50 25C50 29.9706 45.9706 34 41 34H16C11.0294 34 7 29.9706 7 25Z"
      fill="#171717"
    />
    <path
      d="M15.672 29.5H17.688V26.092H20.856V24.46H17.688V22.78H21.624V21.052H15.672V29.5ZM28.0576 29.5H30.1696L26.9056 21.052H24.6016L21.3376 29.5H23.4496L24.0136 27.916H27.4936L28.0576 29.5ZM24.5896 26.284L25.7536 23.02L26.9176 26.284H24.5896ZM32.7583 29.5V21.052H30.7423V29.5H32.7583ZM41.3078 29.5L39.2318 26.344C40.3478 25.864 40.9238 24.868 40.9238 23.788C40.9238 22.3 39.9158 21.052 37.6358 21.052H34.0118V29.5H36.0278V26.62H37.2278L39.0038 29.5H41.3078ZM36.0278 22.78H37.5278C38.3558 22.78 38.9078 23.128 38.9078 23.884C38.9078 24.556 38.3558 24.988 37.5278 24.988H36.0278V22.78Z"
      fill="#DFDFDF"
    />
    <path
      d="M0 9.84252C0 4.40665 4.40665 0 9.84252 0H47.6553C53.0912 0 57.4978 4.40665 57.4978 9.84252C57.4978 15.2784 53.0912 19.685 47.6553 19.685H9.84252C4.40665 19.685 0 15.2784 0 9.84252Z"
      fill="#171717"
    />
    <path
      d="M9.06398 9.67623C9.06398 12.3403 10.6388 14.5056 13.4866 14.5056C15.0089 14.5056 15.9144 13.692 16.3081 13.1933L16.4656 14.2957H18.0929V9.25628H13.7884V11.0411H15.8881C15.8094 11.6316 15.0482 12.6159 13.6834 12.6159C12.1086 12.6159 11.2687 11.3035 11.2687 9.67623C11.2687 8.04894 12.2661 6.7366 13.8278 6.7366C14.9564 6.7366 15.6519 7.36652 15.9013 8.20642H18.0929C17.8829 6.48726 16.4262 4.84684 13.8146 4.84684C10.9013 4.84684 9.06398 7.01219 9.06398 9.67623ZM23.6565 4.84684C20.6644 4.84684 18.9321 7.01219 18.9321 9.67623C18.9321 12.3403 20.6644 14.5056 23.6565 14.5056C26.6487 14.5056 28.381 12.3403 28.381 9.67623C28.381 7.01219 26.6487 4.84684 23.6565 4.84684ZM23.6565 12.6159C22.1474 12.6159 21.1369 11.3035 21.1369 9.67623C21.1369 8.04894 22.1474 6.7366 23.6565 6.7366C25.1657 6.7366 26.1762 8.04894 26.1762 9.67623C26.1762 11.3035 25.1657 12.6159 23.6565 12.6159ZM33.7297 4.84684C30.7376 4.84684 29.0053 7.01219 29.0053 9.67623C29.0053 12.3403 30.7376 14.5056 33.7297 14.5056C36.7219 14.5056 38.4542 12.3403 38.4542 9.67623C38.4542 7.01219 36.7219 4.84684 33.7297 4.84684ZM33.7297 12.6159C32.2206 12.6159 31.2101 11.3035 31.2101 9.67623C31.2101 8.04894 32.2206 6.7366 33.7297 6.7366C35.2389 6.7366 36.2494 8.04894 36.2494 9.67623C36.2494 11.3035 35.2389 12.6159 33.7297 12.6159ZM42.9524 5.05681H39.396V14.2957H42.9524C46.1151 14.2957 47.5849 12.1172 47.5849 9.67623C47.5849 7.23529 46.1939 5.05681 42.9524 5.05681ZM42.9918 12.4059H41.6007V6.94657H42.9918C44.5928 6.94657 45.3802 8.28516 45.3802 9.67623C45.3802 11.0673 44.5928 12.4059 42.9918 12.4059Z"
      fill="#DFDFDF"
    />
  </svg>
);

export default CTARatingsIcon;
