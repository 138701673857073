/* eslint-disable react/static-property-placement */
import React, { useMemo } from 'react';
import { Typography, Button, Box } from '@mui/material';
import Image from 'next/image';
import Skeleton from 'react-loading-skeleton';
import withContentCards from 'components/withContentCards';
import { trackEvent } from '@surfline/web-common';

import { CLICKED_SUBSCRIBE_CTA } from 'common/constants';
import useConditions from 'hooks/useConditions';
import {
  useMinWidthTabletLarge,
  useMinWidthMobileLarge,
  useMinWidthTablet,
} from 'hooks/useMediaQueries';

// @ts-ignore Image can't be found on this path
import paywallImageSmall from '../../../public/images/paywalls/paywall-spot-small.png';
// @ts-ignore Image can't be found on this path
import paywallImageMedium from '../../../public/images/paywalls/paywall-spot-medium.png';
// @ts-ignore Image can't be found on this path
import paywallImageLarge from '../../../public/images/paywalls/paywall-spot-large.png';
// @ts-ignore Image can't be found on this path
import paywallTrafficLightImageSmall from '../../../public/images/paywalls/paywall-spot-traffic-light-small.png';
// @ts-ignore Image can't be found on this path
import paywallTrafficLightImageMedium from '../../../public/images/paywalls/paywall-spot-traffic-light-medium.png';
// @ts-ignore Image can't be found on this path
import paywallTrafficLightImageLarge from '../../../public/images/paywalls/paywall-spot-traffic-light-large.png';

import styles from './ForecastGraphsCTA.module.scss';

const IMAGES = {
  small: {
    alt: 'Example of premium forecast experience',
    height: '1386',
    width: '687',
    src: paywallImageSmall,
    trafficLightSrc: paywallTrafficLightImageSmall,
  },
  medium: {
    alt: 'Example of premium forecast experience',
    height: '1386',
    width: '687',
    src: paywallImageMedium,
    trafficLightSrc: paywallTrafficLightImageMedium,
  },
  large: {
    alt: 'Example of premium forecast experience',
    height: '1386',
    width: '687',
    src: paywallImageLarge,
    trafficLightSrc: paywallTrafficLightImageLarge,
  },
};

interface BrazeCard {
  name?: string;
  extras: {
    box__title: string;
    box__subtitle: string;
    box__body: string;
    box__background: string;
    box__button: string;
    box__link: string;
  } | null;
}

type Props = {
  card?: BrazeCard;
  daysToDisplay: Array<number>;
  segmentProperties: {
    category: string;
    pageName: string;
    spotId: string;
    subregionId: string;
  };
};

const ForecastGraphsCTA: React.FC<Props> = ({ card, segmentProperties, daysToDisplay }) => {
  const isMinWidthMobileLarge = useMinWidthMobileLarge();
  const isMinWidthTablet = useMinWidthTablet();
  const isMinWidthTabletLarge = useMinWidthTabletLarge();
  const { colorTheme } = useConditions();

  const visibleSpots = useMemo(() => {
    let spots = 0;
    if (daysToDisplay.includes(0)) spots += 1;
    if (daysToDisplay.includes(1)) spots += 1;
    if (daysToDisplay.includes(2)) spots += 1;
    if (daysToDisplay.includes(3)) spots += 1;
    if (daysToDisplay.includes(4)) spots += 1;
    return spots;
  }, [daysToDisplay]);

  const paywallWidth = useMemo(() => {
    let offset = 40;
    if (isMinWidthMobileLarge) offset = visibleSpots === 0 ? 60 : 30;
    if (isMinWidthTablet) offset = visibleSpots === 0 ? 80 : 40;
    if (isMinWidthTabletLarge) offset = 0;
    return `calc(${(1 - visibleSpots / daysToDisplay.length) * 100}% + ${offset}px)`;
  }, [daysToDisplay, isMinWidthTabletLarge, isMinWidthMobileLarge, isMinWidthTablet, visibleSpots]);

  const isVisible = useMemo(
    () => paywallWidth !== '0%' && !paywallWidth.startsWith('calc(0% +'),
    [paywallWidth],
  );

  const size = useMemo(() => {
    let currentSize = 'small';
    if (
      (daysToDisplay.length >= 2 && visibleSpots === 0) ||
      (daysToDisplay.length >= 3 && visibleSpots === 1) ||
      (daysToDisplay.length >= 4 && visibleSpots === 2)
    )
      currentSize = 'medium';
    if (
      (daysToDisplay.length >= 3 && visibleSpots === 0) ||
      (daysToDisplay.length === 4 && visibleSpots < 2)
    )
      currentSize = 'large';
    return currentSize;
  }, [daysToDisplay, visibleSpots]);

  const image = useMemo(() => {
    switch (size) {
      case 'small':
        return IMAGES.small;
        break;
      case 'medium':
        return IMAGES.medium;
        break;
      case 'large':
        return IMAGES.large;
        break;
      default:
        return IMAGES.small;
    }
  }, [size]);

  return (
    <Box
      className={`${styles.ctaWrap} sl-forecast-graphs-cta-wrap`}
      data-testid="sl-forecast-graphs-cta-wrap"
      sx={{
        backgroundColor: card?.extras?.box__background,
        display: isVisible ? 'flex' : 'none',
        width: paywallWidth,
      }}
    >
      <Typography className={styles.ctaTitle} variant="title2">
        {card?.extras?.box__title || <Skeleton baseColor="#eae8dc" highlightColor="#e0ddce" />}
      </Typography>
      <Typography variant="body1">
        {card?.extras?.box__body || <Skeleton baseColor="#eae8dc" highlightColor="#e0ddce" />}
      </Typography>
      <Button
        onClick={() => {
          trackEvent(CLICKED_SUBSCRIBE_CTA, {
            ...segmentProperties,
            location: size === 'large' ? 'chart - full overlay' : 'chart - top',
            size,
          });
        }}
        className={styles.ctaButton}
        variant="primary"
        size="medium"
        href={card?.extras?.box__link || '/upgrade'}
        aria-label="premium upgrade link"
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        {card?.extras?.box__button}
      </Button>
      <Typography variant="subtitle1" mb={4}>
        {card?.extras?.box__subtitle || <Skeleton baseColor="#eae8dc" highlightColor="#e0ddce" />}
      </Typography>
      <Image
        alt={image.alt}
        height={image.height}
        objectFit="contain"
        objectPosition="top"
        src={colorTheme === 'Traffic Light' ? image.trafficLightSrc : image.src}
        unoptimized
        width={image.width}
      />
    </Box>
  );
};

export default withContentCards(ForecastGraphsCTA as React.ComponentType<{}>);
