import React from 'react';
import { Box, Button, Grid, Stack, Typography } from '@mui/material';
import withContentCards from 'components/withContentCards';
import { trackEvent } from '@surfline/web-common';
import { PremiumCTA } from '../PremiumCTA/PremiumCTA';
import Styles from './PremiumFeatureCTA.module.scss';

interface BrazeCard {
  name?: string;
  extras?: {
    box__title__one?: string;
    box__body__one?: string;
    box__icon__one?: string;
    box__link__one?: string;
    box__visible__one?: string;
    box__title__two?: string;
    box__body__two?: string;
    box__icon__two?: string;
    box__link__two?: string;
    box__visible__two?: string;
    box__title__three?: string;
    box__body__three?: string;
    box__icon__three?: string;
    box__link__three?: string;
    box__visible__three?: string;
    box__background?: string;
    box__headline?: string;
  } | null;
}

type Props = {
  card?: any;
  dataTest?: string;
  /* The Braze HOC relies on the defaultCard prop to be passed in but it handles it internally.
     We declare it here so we can set default values in defaultProps for it to handle */
  // eslint-disable-next-line react/no-unused-prop-types
  defaultCard?: BrazeCard;
  // eslint-disable-next-line react/no-unused-prop-types
  segmentProperties?: {
    spotName: string;
    subregionId: string;
    spotId: string;
    pageName: string;
    location: string;
  };
  isLink?: boolean;
};

const PremiumFeatureCTA: React.FC<Props> = ({
  card,
  dataTest = 'premium-feature-cta',
  segmentProperties,
  isLink = false,
}) => {
  const mappedBrazeCards = ['one', 'two', 'three'].map((cardValue) => {
    const boxTitle = card?.extras?.[`box__title__${cardValue}`];
    const boxBody = card?.extras?.[`box__body__${cardValue}`];
    const boxIcon = card?.extras?.[`box__icon__${cardValue}`];
    const boxLink = card?.extras?.[`box__link__${cardValue}`];
    const boxVisible = card?.extras?.[`box__visible__${cardValue}`];

    const boxBackground = card?.extras?.box__background;

    return boxVisible === 'visible' ? (
      <Grid item mobile={12} tabletLg={4} className={Styles.card} key={`premiumCta-${cardValue}`}>
        <PremiumCTA
          key={cardValue}
          card={{
            name: 'SPOT_PAGE_PREMIUM_FEATURE_MODULE',
            extras: {
              box__title: boxTitle,
              box__body: boxBody,
              box__icon: boxIcon,
              box__link: boxLink,
              box__background: boxBackground,
            },
          }}
          defaultCard={{
            extras: {
              box__title: boxTitle,
              box__body: boxBody,
              box__icon: boxIcon,
              box__link: boxLink,
              box__background: boxBackground,
            },
          }}
          className="premium-feature-cta-item"
          cssModules={Styles}
          segmentProperties={segmentProperties}
          isLink={isLink}
        />
      </Grid>
    ) : null;
  });

  const onClickHandler = () => {
    trackEvent('Clicked Subscribe CTA', segmentProperties);
  };

  return (
    <Box py={2} data-testid={dataTest} className={Styles.cta}>
      <Stack direction={{ mobile: 'column', tablet: 'row' }} mb={2}>
        <Typography component="h4" variant="title2" mb={2} width="100%">
          {card?.extras?.box__headline}
        </Typography>
        {card?.extras?.box__cta && card?.extras?.box__cta_link && (
          <Button
            variant="primary"
            size="medium"
            sx={{ width: '175px' }}
            href={card.extras.box__cta_link}
            onClick={onClickHandler}
          >
            {card.extras.box__cta}
          </Button>
        )}
      </Stack>
      <Box className={Styles.container}>
        <Grid container spacing={2} className={Styles.grid}>
          {mappedBrazeCards}
        </Grid>
      </Box>
    </Box>
  );
};

export default withContentCards(PremiumFeatureCTA as React.ComponentType<{}>);
