import { TypographyVariant } from '@mui/material';

const getTooltipTextStyles = (
  isMobileView: boolean,
  isTooltipMode: boolean,
  mobileTooltip: string,
  desktopTooltip: string,
  mobile: string,
  desktop: string,
) => {
  if (isTooltipMode && !isMobileView) {
    return desktopTooltip as TypographyVariant;
  }
  if (isTooltipMode && isMobileView) {
    return mobileTooltip as TypographyVariant;
  }
  if (isMobileView) {
    return mobile as TypographyVariant;
  }

  return desktop as TypographyVariant;
};

export default getTooltipTextStyles;
