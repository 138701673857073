import React from 'react';
import { Chip, Typography } from '@mui/material';
import { SurflineWaterDrop } from 'components/Icons';
import Styles from './PremiumChip.module.scss';

export const PremiumChip: React.FC = () => (
  <Chip
    avatar={<SurflineWaterDrop />}
    label={
      <Typography color="brand.secondary" variant="callout1">
        Premium
      </Typography>
    }
    size="small"
    className={Styles.chip}
  />
);

export default PremiumChip;
