import React from 'react';

const CTAWaveSquareIcon = () => (
  <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.5" y="1.5" width="47" height="47" rx="6.5" stroke="#171717" strokeWidth="3" />
    <mask
      id="mask0_2855_30717"
      style={{ maskType: 'alpha' }}
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="50"
      height="50"
    >
      <rect width="50" height="50" rx="8" fill="#F48080" />
    </mask>
    <g mask="url(#mask0_2855_30717)">
      <path
        d="M35 23.4999C27 16.5001 16 19.5001 3 30.0001V54H49V36.9429C29.5 36.9429 22 25.0001 35 23.4999Z"
        fill="#171717"
        stroke="#171717"
        strokeWidth="2"
      />
    </g>
    <path
      d="M8.03559 16H9.71559V13.16H12.3556V11.8H9.71559V10.4H12.9956V8.96H8.03559V16ZM19.8773 16L18.1473 13.37C19.0773 12.97 19.5573 12.14 19.5573 11.24C19.5573 10 18.7173 8.96 16.8173 8.96H13.7973V16H15.4773V13.6H16.4773L17.9573 16H19.8773ZM15.4773 10.4H16.7273C17.4173 10.4 17.8773 10.69 17.8773 11.32C17.8773 11.88 17.4173 12.24 16.7273 12.24H15.4773V10.4ZM22.2839 16V8.96H20.6039V16H22.2839ZM26.0386 8.96H23.3286V16H26.0386C28.4486 16 29.5686 14.34 29.5686 12.48C29.5686 10.62 28.5086 8.96 26.0386 8.96ZM26.0686 14.56H25.0086V10.4H26.0686C27.2886 10.4 27.8886 11.42 27.8886 12.48C27.8886 13.54 27.2886 14.56 26.0686 14.56ZM35.0854 16H36.8454L34.1254 8.96H32.2054L29.4854 16H31.2454L31.7154 14.68H34.6154L35.0854 16ZM32.1954 13.32L33.1654 10.6L34.1354 13.32H32.1954ZM40.7819 8.96L39.2419 11.76L37.7019 8.96H35.8819L38.4019 13.36V16H40.0819V13.36L42.6019 8.96H40.7819Z"
      fill="#171717"
    />
    <path
      d="M16.4 40.9C16.4 42.9 17.35 44.16 19.04 44.16C20.58 44.16 21.68 43.02 21.68 41.6C21.68 40.25 20.79 39.2 19.41 39.2C18.78 39.2 18.29 39.44 18.04 39.73C18.08 39.01 18.28 38.2 19.12 38.2C19.62 38.2 19.85 38.49 19.95 38.82H21.51C21.44 37.79 20.5 36.8 19.15 36.8C17.12 36.8 16.4 38.58 16.4 40.9ZM18 41.6C18 40.98 18.42 40.48 19.04 40.48C19.66 40.48 20.08 40.98 20.08 41.6C20.08 42.22 19.66 42.72 19.04 42.72C18.42 42.72 18 42.22 18 41.6ZM25.684 42V40.88H22.484V42H25.684ZM31.0079 40.31C31.4879 39.97 31.7679 39.48 31.7679 38.88C31.7679 37.73 30.7879 36.8 29.2079 36.8C27.5379 36.8 26.6479 37.73 26.6479 38.88C26.6479 39.47 26.9079 39.96 27.3779 40.3C26.8079 40.67 26.4879 41.23 26.4879 41.92C26.4879 43.16 27.4479 44.16 29.2079 44.16C30.8979 44.16 31.9279 43.16 31.9279 41.92C31.9279 41.24 31.5879 40.67 31.0079 40.31ZM29.2079 38.24C29.8479 38.24 30.1679 38.58 30.1679 39C30.1679 39.42 29.8479 39.76 29.2079 39.76C28.5679 39.76 28.2479 39.42 28.2479 39C28.2479 38.58 28.5679 38.24 29.2079 38.24ZM29.2079 42.72C28.4779 42.72 28.0879 42.31 28.0879 41.8C28.0879 41.29 28.4779 40.88 29.2079 40.88C29.9379 40.88 30.3279 41.29 30.3279 41.8C30.3279 42.31 29.9379 42.72 29.2079 42.72Z"
      fill="#F4F1E8"
    />
  </svg>
);

export default CTAWaveSquareIcon;
