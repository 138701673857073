import React, { useMemo } from 'react';
import classNames from 'classnames';

import { type TypographyVariant, Typography } from '@mui/material';
import { BRAZE_CONTENT_CARDS } from 'common/constants';
import { SL_WEB_PAYWALLS_V2 } from 'common/treatments';
import { useTreatments } from 'utils/treatments';
import ForecastGraphsCTADaySelector from '../ForecastGraphsCTADaySelector';
import ForecastGraphsCTA from '../ForecastGraphsCTA';
import PremiumButtonCTA from '../PremiumButtonCTA';
import PremiumFeatureCTA from '../PremiumFeatureCTA';
import PremiumImageOverlayCTA from '../PremiumImageOverlayCTA';
import PremiumUpgradePillCTA from '../PremiumUpgradePillCTA';
import PremiumUpgradePillCTAV2 from '../PremiumUpgradePillCTAV2';

import CTANoAdsIcon from '../Icons/CTANoAdsIcon';
import CTARatingsIcon from '../Icons/CTARatingsIcon';
import CTAWaveSquareIcon from '../Icons/CTAWaveSquareIcon';
import { CTACheckmarkIcon } from '../Icons/CTACheckmarkIcon';

import midrollCTAStyles from './MidrollCTA.module.scss';
import premiumAnalysisCTAStyles from './PremiumAnalysisCTA.module.scss';
import premiumChartCTAStyles from './PremiumChartCTA.module.scss';
import premiumCamLabelStyles from './PremiumCamLabel.module.scss';
import premiumTableCTAStyles from './PremiumTableCTA.module.scss';
import premiumSwellSpectraGraphCTAStyles from './PremiumSwellSpectraGraphCTAStyles.module.scss';
import premiumCustomForecastCTAStyles from './PremiumCustomForecastTooltipCTA.module.scss';
import premiumCustomForecastFavoritesCTAStyles from './PremiumCustomForecastFavoritesCTA.module.scss';
import premiumCustomForecastCTAImageStyles from './PremiumCustomForecastTooltipImageStyles.module.scss';
import premiumForecastTableV2CTAStyles from './PremiumForecastTableV2CTA.module.scss';
import premiumHistoricDatePickerCTAStyles from './PremiumHistoricDatePickerCTAStyles.module.scss';
import premiumSingleGraphCTAStyles from './PremiumSingleGraphCTAStyles.module.scss';
import premiumLongRangeTideChartsCTA from './PremiumLongRangeTideChartsCTA.module.scss';
import premiumSwellSPectraCTAImageStyles from './PremiumSwellSpectraCTAImageStyles.module.scss';

const DEFAULT_BRAZE_BOX_BACKGROUND = '#F4F1E8';

export const GraphsCTA: React.FC<{
  daysToDisplay: Array<number>;
  segmentProperties: {
    category: string;
    pageName: string;
    spotId: string;
    subregionId: string;
  };
}> = ({ segmentProperties, daysToDisplay }) => (
  <ForecastGraphsCTA
    card={{
      name: BRAZE_CONTENT_CARDS.FORECAST_GRAPHS_PAYWALL_V2,
    }}
    defaultCard={{
      extras: {
        box__title: 'Find your next wave.',
        box__body:
          'Surfline Premium offers all the information you need to decide when to paddle out.',
        box__subtitle: 'Plan ahead by viewing surf forecasts up to 16 days out.',
        box__background: DEFAULT_BRAZE_BOX_BACKGROUND,
        box__button: 'Start free trial',
        box__link: '/upgrade',
      },
    }}
    daysToDisplay={daysToDisplay}
    segmentProperties={{ ...segmentProperties }}
  />
);

export const GraphsCTADaySelector: React.FC<{
  segmentProperties?: {
    category: string;
    pageName: string;
    spotId: string;
    subregionId: string;
  };
  isDesktop?: boolean;
}> = ({ segmentProperties, isDesktop = false }) => (
  <ForecastGraphsCTADaySelector
    card={{
      name: BRAZE_CONTENT_CARDS.FORECAST_GRAPHS_PAYWALL_DAY_SELECTOR,
    }}
    defaultCard={{
      extras: {
        box__title: 'Waves are on the horizon.',
        box__body: 'Get 16-days of forecasts, reports, and ad-free cams today.',
        box__background: DEFAULT_BRAZE_BOX_BACKGROUND,
        box__button: 'Start free trial',
        box__link: '/upgrade',
      },
    }}
    segmentProperties={{ ...segmentProperties }}
    isDesktop={isDesktop}
  />
);

export const SpotPagePremiumFeatureCTA: React.FC<{
  segmentProperties: {
    spotName: string;
    subregionId: string;
    spotId: string;
  };
}> = ({ segmentProperties }) => (
  <PremiumFeatureCTA
    isLink
    segmentProperties={{
      pageName: 'Spot Forecast',
      location: 'Premium Feature Module',
      ...segmentProperties,
    }}
    card={{ name: BRAZE_CONTENT_CARDS.SPOT_PAGE_PREMIUM_FEATURE_MODULE }}
    defaultCard={{
      extras: {
        box__title__one: `Every cam, ad-free. `,
        box__visible__one: 'visible',
        box__body__one: `Get unlimited access to 800+ cams around the world without ads.`,
        box__icon__one: <CTANoAdsIcon />,
        box__link__one: '/upgrade',
        box__title__two: `Daily reports from our team.`,
        box__visible__two: 'visible',
        box__body__two: `Read up-to-date reports from our seasoned forecasters, highlighting conditions in your region.`,
        box__icon__two: <CTARatingsIcon />,
        box__link__two: '/upgrade',
        box__title__three: `Extended 16-day forecasts.`,
        box__visible__three: 'visible',
        box__body__three: `View detailed long-range forecasts from our in-house experts. `,
        box__icon__three: <CTAWaveSquareIcon />,
        box__link__three: '/upgrade',
        box__background: DEFAULT_BRAZE_BOX_BACKGROUND,
        box__headline: 'Find your next wave with Premium.',
        box__cta: 'Start free trial',
        box__cta_link: '/upgrade',
      },
    }}
  />
);

export const PremiumFCSTUpgradePill: React.FC<{}> = () => {
  const treatments = useTreatments();
  return treatments[SL_WEB_PAYWALLS_V2] === 'on' ? (
    <PremiumUpgradePillCTAV2
      dataTest="premium-fcst-upgrade-pill-cta"
      card={{ name: BRAZE_CONTENT_CARDS.PREMIUM_PILL_FCST }}
      defaultCard={{
        extras: {
          box__title: 'Get more with Premium.',
          box__body: 'Start free trial',
          box__link: '/upgrade',
        },
      }}
      segmentProperties={{
        location: 'Premium Pill Forecast',
      }}
    />
  ) : (
    <PremiumUpgradePillCTA
      dataTest="premium-fcst-upgrade-pill-cta"
      card={{ name: BRAZE_CONTENT_CARDS.PREMIUM_PILL_FCST }}
      defaultCard={{
        extras: {
          box__title: 'Get more with Premium.',
          box__body: 'Start free trial',
          box__link: '/upgrade',
        },
      }}
      segmentProperties={{
        location: 'Premium Pill Forecast',
      }}
    />
  );
};

export const PremiumSpotUpgradePill: React.FC<{
  segmentProperties: {
    location: string;
    subregionId: string;
    spotId: string;
    pageName: string;
  };
}> = ({ segmentProperties }) => {
  const treatments = useTreatments();
  return treatments[SL_WEB_PAYWALLS_V2] === 'on' ? (
    <PremiumUpgradePillCTAV2
      dataTest="premium-spot-upgrade-pill-cta"
      card={{ name: BRAZE_CONTENT_CARDS.PREMIUM_PILL_SPOT }}
      defaultCard={{
        extras: {
          box__title: 'Get more with Premium',
          box__body: 'Start free trial',
          box__link: '/upgrade',
        },
      }}
      segmentProperties={segmentProperties}
    />
  ) : (
    <PremiumUpgradePillCTA
      dataTest="premium-spot-upgrade-pill-cta"
      card={{ name: BRAZE_CONTENT_CARDS.PREMIUM_PILL_SPOT }}
      defaultCard={{
        extras: {
          box__title: 'Get more with Premium',
          box__body: 'Start free trial',
          box__link: '/upgrade',
        },
      }}
      segmentProperties={segmentProperties}
    />
  );
};

export const PremiumChartCTA: React.FC<{
  segmentProperties: {
    location: string;
    subregion: string;
    category: string;
    pageName: string;
  };
  className: string;
}> = ({ segmentProperties, className }) => (
  <PremiumButtonCTA
    className={className}
    cssModules={premiumChartCTAStyles}
    segmentProperties={segmentProperties}
    title="High-definition charts, at your finger tips"
    body="Get access to expert data with our propietary charts, and find your next wave, window and
    spot"
    buttonText="Start free trial"
    link="/upgrade"
  />
);

export const PremiumLongRangeTidesCTA: React.FC<{
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
    spotId: string | undefined;
    spotName: string;
  };
  propertiesCTA: {
    title: string;
    buttonText: string;
    subTitle: string;
  };
}> = ({ segmentProperties, propertiesCTA }) => (
  <PremiumButtonCTA
    cssModules={premiumLongRangeTideChartsCTA}
    segmentProperties={segmentProperties}
    title={propertiesCTA.title}
    body={propertiesCTA.subTitle}
    buttonText={propertiesCTA.buttonText}
    link="/upgrade"
    dataTest="lrt-premium-cta-banner"
  />
);

export const PremiumAnalysisCTA: React.FC<{
  segmentProperties: {
    location: string;
    contentId: string;
    subregionId: string;
    subregionName: string;
  };
  link?: string;
}> = ({ segmentProperties, link = '/upgrade' }) => (
  <PremiumButtonCTA
    cssModules={premiumAnalysisCTAStyles}
    segmentProperties={segmentProperties}
    title="Want access to Premium Analysis from the Surfline forecast team?"
    buttonText="Start free trial"
    link={link}
  />
);

export const PremiumTableCTA: React.FC<{
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
    spotId: string;
    subregionId: string;
  };
  className: string;
}> = ({ segmentProperties, className }) => (
  <PremiumButtonCTA
    className={className}
    cssModules={premiumTableCTAStyles}
    segmentProperties={segmentProperties}
    title="Swell is on the horizon."
    body="Get access to expert data with our propietary charts, and find your next wave, window and spot"
    buttonText="Start free trial"
    link="/upgrade"
  />
);

export const PremiumForecastTableV2CTA: React.FC<{
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
    spotId: string | undefined;
    subregionId: string | undefined;
    size: string;
  };
  className?: string;
}> = ({ segmentProperties, className }) => (
  <PremiumButtonCTA
    className={className}
    cssModules={premiumForecastTableV2CTAStyles}
    dataTest="forecast-table-v2-paywall"
    segmentProperties={segmentProperties}
    title="See 16 days of detailed data"
    titleVariant={'headline' as TypographyVariant}
    body="Plan ahead and find your surf windows with access to detailed 16-day forecasts showing conditions, surf height, swell period and direction, and more."
    bodyVariant="body1"
    buttonText="Start free trial"
    buttonVariant="medium"
    link="/upgrade"
  />
);

export const PremiumHistoricDatePickerCTA: React.FC<{
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
    spotId: string | undefined;
    spotName: string;
  };
  SecondaryButton?: () => React.ReactNode | null;
  buttonText: string;
  className?: string;
  title: string;
  subTitle: string;
}> = ({ segmentProperties, className, SecondaryButton, title, subTitle, buttonText }) => (
  <PremiumButtonCTA
    className={className}
    cssModules={premiumHistoricDatePickerCTAStyles}
    dataTest="historic-date-picker-paywall"
    segmentProperties={segmentProperties}
    title={title}
    titleVariant={'headline' as TypographyVariant}
    body={subTitle}
    bodyVariant="body1"
    buttonText={buttonText}
    buttonVariant="medium"
    link="/upgrade"
    SecondaryButton={SecondaryButton}
  />
);

export const PremiumSingleGraphCTA: React.FC<{
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
    spotId: string;
    subregionId: string;
  };
  buttonText: string;
  className?: string;
  dataTestId?: string;
  title: string;
  subTitle: string | React.ReactNode;
  LeftContent?: () => React.ReactNode;
}> = ({ segmentProperties, className, LeftContent, dataTestId, title, subTitle, buttonText }) => (
  <PremiumButtonCTA
    className={className}
    cssModules={premiumSingleGraphCTAStyles}
    dataTest={dataTestId}
    segmentProperties={segmentProperties}
    title={title}
    titleVariant={'title3' as TypographyVariant}
    body={subTitle}
    bodyVariant="body1"
    buttonText={buttonText}
    buttonVariant="medium"
    link="/upgrade"
    LeftContent={LeftContent}
  />
);

export interface BodyCopyBullets {
  bulletOne: string;
  bulletTwo: string;
  bulletThree: string;
}

export const PremiumSwellSpectraBody = ({ bullets }: { bullets: BodyCopyBullets }) => (
  <ul className={premiumSwellSpectraGraphCTAStyles.ctaBullets}>
    <li className={premiumSwellSpectraGraphCTAStyles.ctaBulletPoint}>
      <CTACheckmarkIcon className={premiumSwellSpectraGraphCTAStyles.ctaCheckmark} />
      <Typography component="p" variant="body1">
        {bullets.bulletOne}
      </Typography>
    </li>
    <li className={premiumSwellSpectraGraphCTAStyles.ctaBulletPoint}>
      <CTACheckmarkIcon className={premiumSwellSpectraGraphCTAStyles.ctaCheckmark} />
      <Typography component="p" variant="body1">
        {bullets.bulletTwo}
      </Typography>
    </li>
    <li className={premiumSwellSpectraGraphCTAStyles.ctaBulletPoint}>
      <CTACheckmarkIcon className={premiumSwellSpectraGraphCTAStyles.ctaCheckmark} />
      <Typography component="p" variant="body1">
        {bullets.bulletThree}
      </Typography>
    </li>
  </ul>
);

export const PremiumSwellSpectraGraphCTA: React.FC<{
  title: string;
  bodyCopyBullets: BodyCopyBullets;
  LeftContent?: () => React.ReactNode;
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
    spotId?: string;
    subregionId?: string;
    buoyId?: string;
    buoyName?: string;
  };
  native?: boolean;
}> = ({ bodyCopyBullets, LeftContent, segmentProperties, title, native }) => (
  <PremiumButtonCTA
    LeftContent={LeftContent}
    body={<PremiumSwellSpectraBody bullets={bodyCopyBullets} />}
    bodyVariant="body1"
    buttonText="Start free trial"
    buttonVariant="medium"
    cssModules={premiumSwellSpectraGraphCTAStyles}
    dataTest="graph-swell-spectra-cta"
    link={native ? 'surfline://upgrade' : '/upgrade'}
    segmentProperties={segmentProperties}
    title={title}
    titleVariant={'title3' as TypographyVariant}
  />
);

export const GraphSwellSpectraCTAImage = () => (
  <div className={premiumSwellSPectraCTAImageStyles.ctaImageWrapper}>
    <div className={premiumSwellSPectraCTAImageStyles.graphSwellSpectraCTAImage} />
  </div>
);

export const PremiumCamLabel: React.FC<{}> = () => (
  <div className={premiumCamLabelStyles.premiumCamLabel} data-testid="premium-cam-icon">
    <span className={premiumCamLabelStyles.premiumCamLabelInner}>Premium</span>
  </div>
);

const getMidrollCtaExtras = (upgradeUrl: string, isEntitled?: boolean, referrer?: string) =>
  isEntitled
    ? {
        box__h1: 'Watch waves, not ads.',
        box__h2: 'Upgrade your membership for ad-free cams.',
        button1__text: 'Upgrade now',
        button1__url: `/change-plan?referrerUrl=${referrer}`,
      }
    : {
        box__h1: 'Watch waves, not ads.',
        box__h2: 'Start your free trial now.',
        button1__text: 'Start Free Trial',
        button1__url: upgradeUrl,
      };

export const MidrollCTA: React.FC<{
  segmentProperties: {
    location: string;
    pageName: string;
    spotId: string;
    spotName: string;
  };
  cssModules?: {
    ctaContainer?: string;
    ctaGridContainer?: string;
    ctaImageWrapper?: string;
    ctaContent?: string;
    ctaLinks?: string;
    ctaHeading?: string;
    ctaSubheading?: string;
    ctaUpgradeBtn?: string;
    ctaAboutBtn?: string;
  };
  isEntitled: boolean;
  referrer?: string;
  stillUrl: string;
  spotName: string;
  upgradeUrl: string;
}> = ({ segmentProperties, cssModules, isEntitled, referrer, stillUrl, spotName, upgradeUrl }) => {
  const midrollCard = useMemo(
    () => ({
      name: 'CAM_MIDROLL_CTA',
      ...getMidrollCtaExtras(upgradeUrl, isEntitled, referrer),
    }),
    [isEntitled, referrer, upgradeUrl],
  );

  return (
    <div className={classNames(midrollCTAStyles.camMidrollUpsell, 'sl-cam-midroll-upsell')}>
      <PremiumImageOverlayCTA
        card={midrollCard}
        cssModules={cssModules}
        imageAlt="Recent conditions"
        imageUrl={stillUrl}
        segmentProperties={segmentProperties}
        spotName={spotName}
      />
    </div>
  );
};

export const PremiumCustomForecastTooltipCTA: React.FC<{
  dataTest?: string;
  enableAnimation: boolean;
  LeftContent?: () => React.ReactNode;
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
  };
  SecondaryButton?: () => React.ReactNode | null;
  showPaywall: boolean;
}> = ({
  dataTest,
  enableAnimation,
  LeftContent,
  segmentProperties,
  showPaywall,
  SecondaryButton,
}) => {
  const animateOnOpen = !enableAnimation || (enableAnimation && showPaywall);
  const premiumButtonCTAStyles = useMemo(
    () => ({
      ...premiumCustomForecastCTAStyles,
      ctaButton: animateOnOpen
        ? premiumCustomForecastCTAStyles.ctaButtonShow
        : premiumCustomForecastCTAStyles.ctaButtonHide,
      ctaContent: animateOnOpen
        ? premiumCustomForecastCTAStyles.ctaContentShow
        : premiumCustomForecastCTAStyles.ctaContentHide,
      ctaWrap: animateOnOpen
        ? premiumCustomForecastCTAStyles.ctaWrapShow
        : premiumCustomForecastCTAStyles.ctaWrapHide,
    }),
    [animateOnOpen],
  );
  return (
    <PremiumButtonCTA
      body="Premium members can create and save detailed 16-day forecasts for custom spots anywhere in the world."
      bodyVariant="body1"
      buttonText="Start free trial"
      buttonVariant="medium"
      cssModules={premiumButtonCTAStyles}
      dataTest={dataTest || 'custom-forecast-paywall'}
      LeftContent={LeftContent}
      link="/upgrade"
      SecondaryButton={SecondaryButton}
      segmentProperties={segmentProperties}
      title="Get swell forecasts for any point in the ocean."
      titleVariant={'title3' as TypographyVariant}
    />
  );
};

export const PremiumCustomForecastTooltipCTAImage = () => (
  <div className={premiumCustomForecastCTAImageStyles.ctaImageWrapper}>
    <div className={premiumCustomForecastCTAImageStyles.customForecastTooltipCTAImage} />
  </div>
);

export const PremiumCustomForecastFavoritesCTA: React.FC<{
  dataTest?: string;
  segmentProperties: {
    location: string;
    category: string;
    pageName: string;
  };
}> = ({ dataTest, segmentProperties }) => (
  <PremiumButtonCTA
    body="Premium members can create and save detailed 16-day forecasts for custom spots anywhere in the world."
    bodyVariant="body1"
    buttonText="Start free trial"
    buttonVariant="medium"
    cssModules={{
      ctaTitle: premiumCustomForecastFavoritesCTAStyles.ctaTitle,
      ctaBody: premiumCustomForecastFavoritesCTAStyles.ctaBody,
      ctaButton: premiumCustomForecastFavoritesCTAStyles.ctaButton,
    }}
    dataTest={dataTest || 'custom-forecast-favorites-paywall'}
    link="/upgrade"
    segmentProperties={segmentProperties}
    title="Get swell forecasts for any point in the ocean."
    titleVariant={'title3' as TypographyVariant}
  />
);
