import React from 'react';
import withContentCards from 'components/withContentCards';
import classNames from 'classnames';
import { Box, Typography } from '@mui/material';
import Skeleton from 'react-loading-skeleton';
import { trackEvent } from '@surfline/web-common';
import WavetrakLink from '../WavetrakLink';
import getImageComponent from '../../utils/brazeUtils';

import Styles from './PremiumCTA.module.scss';

interface BrazeCard {
  name?: string;
  extras?: {
    box__title: string;
    box__body: string;
    box__icon: string;
    box__link?: string;
    box__background?: string;
  } | null;
}

type Props = {
  card?: BrazeCard;
  className?: string;
  dataTest?: string;
  /* The Braze HOC relies on the defaultCard prop to be passed in but it handles it internally.
     We declare it here so we can set default values in defaultProps for it to handle */
  // eslint-disable-next-line react/no-unused-prop-types
  defaultCard?: BrazeCard;
  styles?: React.CSSProperties | undefined;
  // eslint-disable-next-line react/no-unused-prop-types
  segmentProperties?: any;
  isLink?: boolean;
  cssModules?: {
    ctaCopy?: string;
    ctaSkeletonImage?: string;
    ctaSubtitle?: string;
    ctaTitle?: string;
    ctaWrap?: string;
  };
};

export const PremiumCTA: React.FC<Props> = ({
  card,
  className,
  cssModules,
  dataTest,
  styles,
  isLink = false,
  segmentProperties,
}) => {
  const content = (
    <>
      <Box pr={2} sx={{ width: '100%' }}>
        <Typography component="h3" variant="callout1" mb={1}>
          {card?.extras?.box__title || <Skeleton baseColor="#eae8dc" highlightColor="#e0ddce" />}
        </Typography>
        <Typography
          variant="body1"
          className={classNames(Styles.ctaSubtitle, cssModules?.ctaSubtitle)}
        >
          {card?.extras?.box__body || <Skeleton baseColor="#eae8dc" highlightColor="#e0ddce" />}
        </Typography>
      </Box>
      {card?.extras?.box__icon ? (
        <div>{getImageComponent(card?.extras?.box__icon)}</div>
      ) : (
        <Skeleton
          circle
          className={classNames(Styles.ctaSkeletonImage, cssModules?.ctaSkeletonImage)}
          containerClassName={Styles.ctaSkeletonImage}
          baseColor="#eae8dc"
          highlightColor="#e0ddce"
        />
      )}
    </>
  );

  return isLink && card?.extras ? (
    <WavetrakLink
      className={classNames(Styles.ctaWrap, cssModules?.ctaWrap, className)}
      data-testid={dataTest || 'premium-cta'}
      style={{
        /* This variable is being passed in so it can live at the same stylesheet level as the background hover
        otherwise it will override hover and focus states. */
        // @ts-ignore
        '--cta-background-color': card?.extras?.box__background || '#eae8dc',
        ...styles,
      }}
      href={card?.extras?.box__link || '/upgrade'}
      isExternal
      onClick={() => {
        trackEvent('Clicked Subscribe CTA', segmentProperties);
      }}
    >
      {content}
    </WavetrakLink>
  ) : (
    <div
      className={classNames(Styles.ctaWrap, cssModules?.ctaWrap, className)}
      data-testid={dataTest || 'premium-cta'}
      style={{
        background: card?.extras?.box__background || '#eae8dc',
        ...styles,
      }}
    >
      {content}
    </div>
  );
};

export default withContentCards(PremiumCTA as React.ComponentType<{}>);
