import Image from 'next/image';

const getImageComponent = (icon) => {
  if (typeof icon === 'string') {
    // image is coming from Braze and is a URL
    return (
      <Image
        objectFit="contain"
        width="50px"
        height="50px"
        alt="braze icon"
        src={icon}
        unoptimized
      />
    );
  }

  // otherwise the image is a react component
  return icon;
};

export default getImageComponent;
