/* eslint-disable react/static-property-placement */
import React from 'react';
import { Typography, Button, type TypographyVariant } from '@mui/material';
import classNames from 'classnames';
import { trackEvent } from '@surfline/web-common';
import Styles from './PremiumButtonCTA.module.scss';
import { CLICKED_SUBSCRIBE_CTA } from '../../common/constants';

type Props = {
  dataTest?: string;
  segmentProperties: {
    location: string;
    category?: string;
  };
  title: string;
  titleVariant?: TypographyVariant;
  body?: React.ReactNode;
  bodyVariant?: TypographyVariant;
  buttonText: string;
  buttonVariant?: 'large' | 'medium' | 'small';
  className?: string;
  cssModules?: {
    ctaWrap?: string;
    ctaContent?: string;
    ctaTitle?: string;
    ctaBody?: string;
    ctaButton?: string;
  };
  link: string;
  LeftContent?: () => React.ReactNode;
  SecondaryButton?: () => React.ReactNode;
};

const PremiumButtonCTA: React.FC<Props> = ({
  title,
  titleVariant = 'h4',
  body = '',
  bodyVariant = 'body1',
  buttonText,
  buttonVariant = 'large',
  className,
  cssModules,
  link,
  LeftContent,
  segmentProperties,
  SecondaryButton,
  dataTest,
}) => (
  <div
    className={classNames(Styles.ctaWrap, cssModules?.ctaWrap, className)}
    data-testid={dataTest}
  >
    {SecondaryButton && SecondaryButton()}
    {LeftContent && LeftContent()}
    <div className={classNames(Styles.ctaContent, cssModules?.ctaContent)}>
      <Typography
        className={classNames(Styles.ctaTitle, cssModules?.ctaTitle)}
        variant={titleVariant}
      >
        {title}
      </Typography>
      {body && (
        <Typography
          component="div"
          className={classNames(Styles.ctaBody, cssModules?.ctaBody)}
          variant={bodyVariant}
        >
          {body}
        </Typography>
      )}
      <Button
        className={classNames(Styles.ctaButton, cssModules?.ctaButton)}
        data-testid="premium-button-cta"
        onClick={() => {
          trackEvent(CLICKED_SUBSCRIBE_CTA, {
            ...segmentProperties,
          });
        }}
        variant="primary"
        href={link}
        size={buttonVariant}
        aria-label="premium upgrade link"
        disableFocusRipple
        disableRipple
        disableTouchRipple
      >
        {buttonText}
      </Button>
    </div>
  </div>
);
export default PremiumButtonCTA;
