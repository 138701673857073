import React from 'react';
import { Button, Typography } from '@mui/material';
import { trackEvent } from '@surfline/web-common';
import withContentCards from 'components/withContentCards';
import Styles from './PremiumUpgradePillCTA.module.scss';
import WavetrakLink from '../WavetrakLink';

interface BrazeCard {
  name: string;
  extras: {
    box__body: string;
    box__title: string;
    box__link: string;
  } | null;
}

type Props = {
  card: BrazeCard;
  // eslint-disable-next-line react/no-unused-prop-types
  defaultCard: Pick<BrazeCard, 'extras'>;
  segmentProperties?: {
    location: 'Premium Pill Latest' | 'Premium Pill Forecast';
  };
  dataTest?: string;
};

export const PremiumUpgradePillCTA: React.FC<Props> = ({
  card,
  segmentProperties,
  dataTest = 'premium-upgrade-pill-cta',
}) =>
  card?.extras ? (
    <div className={Styles.ctaWrap} data-testid={dataTest}>
      <div className={Styles.ctaInner}>
        <WavetrakLink
          className={Styles.ctaContent}
          href={card?.extras?.box__link || '/upgrade'}
          isExternal
          onClick={() => {
            trackEvent('Clicked Subscribe CTA', segmentProperties);
          }}
        >
          <Typography
            whiteSpace="nowrap"
            className={Styles.ctaText}
            variant="callout1"
            color="text.brandSecondary"
          >
            {card?.extras?.box__title}
          </Typography>
          <Button
            disableFocusRipple
            disableRipple
            disableTouchRipple
            variant="primary"
            size="medium"
            className={Styles.ctaButton}
          >
            {card?.extras?.box__body}
          </Button>
        </WavetrakLink>
      </div>
    </div>
  ) : null;

export default withContentCards(PremiumUpgradePillCTA as React.ComponentType<{}>);
